export const formErrorMessage = field => {
  const errors = field.validity;
  const messages = [];

  if (errors.tooShort) {
    messages.push("is too short");
  }

  if (errors.valueMissing) {
    messages.push("is required");
  }

  return messages;
}

import { Controller } from "@hotwired/stimulus";
import { enableTracking, disableTracking, trackingEnabled } from "../utilities/tracking";

export default class extends Controller {
  static outlets = ["cookie-banner"];

  connect() {
    if (!trackingEnabled()) {
      disableTracking();
    }
  }

  accept(event) {
    event.preventDefault();

    enableTracking();
    this.cookieBannerOutlet.hide();
  }

  decline(event) {
    event.preventDefault();

    disableTracking();
    this.cookieBannerOutlet.hide();
  }

  reset(event) {
    event.preventDefault();

    disableTracking();
    this.cookieBannerOutlet.reset();
  }
}

import { Controller } from "@hotwired/stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static classes = ["visibility"];

  connect() {
    if (!this.cookieBannerShown) {
      this.show();
    }
  }

  hide() {
    this.element.classList.remove(this.visibilityClass);
    this.cookieBannerShown = true;
  }

  show() {
    this.element.classList.add(this.visibilityClass);
  }

  reset() {
    this.show();
    this.cookieBannerShown = false;
  }

  get cookieBannerShown() {
    return Cookies.get("cookie-banner") === "true";
  }

  set cookieBannerShown(value) {
    Cookies.set("cookie-banner", value);
  }
}

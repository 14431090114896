import { Controller } from "@hotwired/stimulus";

/**
 * The {ClockController} uses the Rolex clock script in the header to reveal
 * the official Rolex retailer clock.
 *
 * As the {RolexRetailerClock} is loaded async we poll for it to become available.
 * When it's available, the script will inject an iframe into the Rolex clock links.
 */
export default class extends Controller {
  static values = {
    apiKey: String,
    lang: String,
    color: String
  };

  connect() {
    this.waitForClockToBeAvailable();
  }

  waitForClockToBeAvailable() {
    if (this.clockScriptAvailable) {
      return this.show();
    }

    setTimeout(() => this.waitForClockToBeAvailable(), 250);
  }

  show() {
    const clock = new RolexRetailerClock();
    clock.getRetailerClock(this.config);
  }

  get config() {
    return {
      dealerAPIKey: this.apiKeyValue,
      lang: this.langValue,
      colour: this.colorValue
    }
  }

  get clockScriptAvailable() {
    try {
      new RolexRetailerClock();
      return true;
    } catch(err) {
      return false;
    }
  }
}

import Cookies from "js-cookie";

/**
 * A simplified way to track events on the Rolex pages.
 *
 * @param {String} eventName The name of the event to track.
 */
export const track = (eventName) => {
  if (window._satellite) {
    window._satellite.track(eventName);
  }
};

export const disableTracking = () => {
  Cookies.set("rlx-consent", "false");

  const rlxCornerEl = document.getElementById("rlx-corner");
  if (rlxCornerEl) {
    rlxCornerEl.contentWindow.postMessage("consentFalse", "https://corners.rolex.com");
  }
};

export const enableTracking = () => {
  Cookies.set("rlx-consent", "true");

  if (window._satellite) {
    track("PageView");
  }

  const rlxCornerEl = document.getElementById("rlx-corner");
  if (rlxCornerEl) {
    rlxCornerEl.contentWindow.postMessage("consentTrue", "https://corners.rolex.com");
    rlxCornerEl.contentWindow.postMessage("consentValidation", "https://corners.rolex.com");
  }
};

export const trackingEnabled = () => {
  return Cookies.get("rlx-consent") !== undefined;
};

export default track;
